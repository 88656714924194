import { Box, Typography, Button, CircularProgress } from "@mui/material"
import AddButton from "../../components/shared/AddButton"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import useGetGuestLists from "./hooks/useGetGuestLists"
import GuestListsTable from "./components/GuestListsTable"
import { scopes } from "../../scopes"
import GuestListDialog from "./components/GuestListDialog"
import styles from './GuestListsScreen.module.css'

const GuestListsScreen: FunctionComponent = () => {
  const [createGuestListOpen, setCreateGuestListOpen] = useState(false)

  const {
    getHouseById,
    house: organization,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const {
    getGuestLists,
    paginationData,
    guestLists,
    loading: guestListsLoading,
    error: guestListsError,
  } = useGetGuestLists()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getGuestLists(Number(organizationId))

          if (organization) {
            localStorage.setItem(
              "activeOrganization",
              JSON.stringify(organization)
            )
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  if (organizationLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <>
    <ScreenContainer
      requiresScope={scopes.organization.guestLists.create}
    >
          <Box className={styles.createButtonContainer} onClick={() => setCreateGuestListOpen(true)}>
      <AddButton className="className" />
    </Box>
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.guestListsScreen}
      >
        {guestListsError && <ErrorComponent error={guestListsError} />}
        {guestListsLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Guest Lists
        </Typography>
        <GuestListsTable
          guestLists={guestLists}
          paginationData={paginationData}
        />
      </Box>
      <GuestListDialog
        isOpen={createGuestListOpen}
        close={() => setCreateGuestListOpen(false)}
        onSuccess={() => getGuestLists(organization?.id!)}
      />
    </ScreenContainer>
    </>
  )
}

export default GuestListsScreen
