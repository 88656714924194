import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import useHandleRequest from "../../../hooks/useHandleRequest"
import { PointsResponse } from "../../../types/points"
import makeRequest, { PagedResponse } from "../../../utils/make-request"

const useGetPointItems = () => {
  const { loading, setLoading, error, setError, authBearer } =
    useHandleRequest()
  const [data, setData] = useState<PointsResponse>()

  const request = useCallback(
    (
      organizationId: number,
    ): Promise<PointsResponse> => {
      return makeRequest<PointsResponse>(
        `${config.apiUrl}/v1/points/items`,
        {
          headers: {
            authorization: `Bearer ${authBearer}`,
            "X-Organization-Id": `${organizationId}`,
          },
        }
      )
    },
    [authBearer]
  )

  const getPointItems = useCallback(
    async (organizationId: number) => {
      setLoading(true)
      setError(null)
      let results
      try {
        results = await request(organizationId)
        setData(results)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }

      return results
    },
    [request, setLoading, setError]
  )

  return {
    loading,
    error,
    getPointItems,
    pointItems: data || undefined,
  }
}

export default useGetPointItems
