import React, { ReactComponentElement, useEffect, useState } from "react"
import BasicTable, {
  PaginationData,
} from "../../../components/shared/BasicTable/BasicTable"
import { OrganizationMember } from "../../../types"
import HouseMemberRow from "./MemberPointsRow"
import useGetPointItems from "../hooks/useGetPointItems"
import { PointItem, PointsResponse } from "../../../types/points"
import useBreakpoint from "../../../hooks/useBreakpoint"
import { Box, Collapse, Typography } from "@mui/material"
import styles from "./MemberPointsTable.module.css"
import { lightTheme } from "../../../constants/theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import MobilePointsTable from "./MobilePointsTable"

interface MemberPointsTableProps {
  members: OrganizationMember[]
  paginationData: PaginationData
  refreshData: () => void
}

const getRows = (members: OrganizationMember[], refreshData: () => void, columns: string[], pointItems?: PointsResponse) => {
  return members.map((member, index) => {
    return (
      <HouseMemberRow isMobile={false} key={index} member={member} pointItems={pointItems ? pointItems : undefined} columns={columns} refreshData={refreshData} />
    )
  })
}

const MemberPointsTable = ({
  members,
  paginationData,
  refreshData,
}: MemberPointsTableProps) => {
  const [ columns, setColumns ] = useState<string[]>(["", "Name", "Points"])
  const breakpoint = useBreakpoint()


  const {
    getPointItems,
    pointItems,
    loading: pointItemsLoading,
    error: pointItemsError,
  } = useGetPointItems()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = Number(localStorage.getItem("activeOrganizationId"))
        if (organizationId) {
          const response = await getPointItems(Number(organizationId))
          if (response) {
            const newColumns = ["Name", "Total Points", ...response.items.map((item) => item.title)]
            setColumns(newColumns)
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${pointItemsError}`)
      }
    }

    fetchData()
  }, [members])

  if(breakpoint === "lg" || breakpoint === "xl") {
  return (
    <BasicTable
    columns={columns}
    rows={getRows(members, refreshData, columns, pointItems)}
    paginationOptions={paginationData}
    hasHeader={true}
  />
  )
  } else {
    return(
      <MobilePointsTable categories={columns} refreshData = {refreshData}/>
    )
  }
}

export default MemberPointsTable
