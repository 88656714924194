import React, { FunctionComponent } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import LoginScreen from "../../screens/Login/LoginScreen"
import BaseLayout from "../shared/BaseLayout"
import ProtectedRoute from "./ProtectedRoute"
import CreateOrganizationScreen from "../../screens/Organizations/CreateOrganizationScreen"
import DashboardScreen from "../../screens/Dashboard/DashboardScreen"
import AccountScreen from "../../screens/Users/AccountScreen"
import JoinOrganizationScreen from "../../screens/Organizations/JoinOrganizationScreen"
import OrganizationMembersScreen from "../../screens/Organizations/OrganizationMembersScreen"
import NotificationsScreen from "../../screens/Users/NotificationsScreen"
import HousesScreen from "../../screens/Users/HousesScreen"
import OrganizationSettingsScreen from "../../screens/Organizations/OrganizationSettingsScreen"
import SignupScreen from "../../screens/Signup/SignupScreen"
import PointsScreen from "../../screens/Points/PointsScreen"
import WelcomeScreen from "../../screens/Dashboard/WelcomeScreen"
import AnnouncementsScreen from "../../screens/Announcements/AnnouncementsScreen"
import StudyHoursScreen from "../../screens/StudyHours/StudyHoursScreen"
import StudyLocationsScreen from "../../screens/StudyHours/StudyLocationsScreen"
import GuestListsScreen from "../../screens/GuestLists/GuestListsScreen"
import GuestListDetailsScreen from "../../screens/GuestLists/GuestListDetailsScreen"
import SchoolsScreen from "../../screens/Schools/SchoolsScreen"
import PollDetailsScreen from "../../screens/Dashboard/PollDetailsScreen"

const Router: FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<BaseLayout />}>
      <Route
        path="/"
        element={<ProtectedRoute children={<DashboardScreen />} />}
      />
      <Route
        path="/welcome"
        element={<ProtectedRoute children={<WelcomeScreen />} />}
      />
      <Route
        path="/organizations"
        element={<ProtectedRoute children={<HousesScreen />} />}
      />
      <Route
        path="/organizations/create"
        element={<ProtectedRoute children={<CreateOrganizationScreen />} />}
      />
      <Route
        path="/organizations/join"
        element={<ProtectedRoute children={<JoinOrganizationScreen />} />}
      />
      <Route
        path="/account"
        element={<ProtectedRoute children={<AccountScreen />} />}
      />
      <Route
        path="/account/organizations"
        element={<ProtectedRoute children={<HousesScreen />} />}
      />
      <Route
        path="/account/notifications"
        element={<ProtectedRoute children={<NotificationsScreen />} />}
      />
      <Route
        path="/account/notifications"
        element={<ProtectedRoute children={<NotificationsScreen />} />}
      />
      <Route
        path="/members"
        element={<ProtectedRoute children={<OrganizationMembersScreen />} />}
      />
      <Route
        path="/members/points"
        element={<ProtectedRoute children={<PointsScreen />} />}
      />
      <Route
        path="/study-hours"
        element={<ProtectedRoute children={<StudyHoursScreen />} />}
      />
      <Route
        path="/study-hours/locations"
        element={<ProtectedRoute children={<StudyLocationsScreen />} />}
      />
      <Route
        path="/guest-lists"
        element={<ProtectedRoute children={<GuestListsScreen />} />}
      />
      <Route
        path="/guest-lists/:guestListId"
        element={<ProtectedRoute children={<GuestListDetailsScreen />} />}
      />
      <Route
        path="/polls/:pollId"
        element={<ProtectedRoute children={<PollDetailsScreen />} />}
      />
      <Route
        path="/announcements"
        element={<ProtectedRoute children={<AnnouncementsScreen />} />}
      />
      <Route
        path="/settings"
        element={<ProtectedRoute children={<OrganizationSettingsScreen />} />}
      />
      <Route
        path="/internal/schools"
        element={<ProtectedRoute children={<SchoolsScreen />} />}
      />
    </Route>
    <Route path="/login" element={<LoginScreen />} />
    <Route path="/signup" element={<SignupScreen />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router
