import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import { useParams } from "react-router-dom"
import useGetGuestListById from "./hooks/useGetGuestListById"
import AddIcon from "@mui/icons-material/Add"
import SettingsIcon from "@mui/icons-material/Settings"
import toast from "react-hot-toast"
import { GuestListMember } from "../../types"
import useCreateGuestListGuest from "./hooks/useCreateGuestListGuest"
import { useAuth } from "../../services/auth-service"
import DeleteIcon from "@mui/icons-material/Delete"
import useDeleteGuestListGuest from "./hooks/useDeleteGuestListGuest"
import GuestListDialog from "./components/GuestListDialog"
import { useMenu } from "../../components/shared/MenuContext"
import styles from "./GuestListDetailsScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { useNavigate } from "react-router-dom"
import GuestListDetail from "./components/GuestListDetail"
import { Settings } from "@mui/icons-material"


type Params = {
  guestListId?: string
}

const GuestListDetailsScreen: FunctionComponent = () => {
  const { menuIsShowing } = useMenu()
  const params: Params = useParams()
  const guestListId = Number(params.guestListId)
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredMembers, setFilteredMembers] = useState<GuestListMember[]>()
  const [hasResults, setHasResults] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [newGuestName, setNewGuestName] = useState("")

  const { getUser } = useAuth()
  const currentUserId = getUser()?.id

  const navigate = useNavigate()

  const {
    getHouseById,
    house,
    loading: organizationLoading,
    error: organizationError,
  } = useGetHouseById()
  const {
    getGuestList,
    guestList,
    loading: listLoading,
    error: listError,
  } = useGetGuestListById()
  const {
    createGuestListGuest,
    loading: createGuestLoading,
    error: createGuestError,
  } = useCreateGuestListGuest()
  const {
    deleteGuestListGuest,
    loading: deleteGuestLoading,
    error: deleteGuestError,
  } = useDeleteGuestListGuest()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationId = localStorage.getItem("activeOrganizationId")

        if (organizationId) {
          await getHouseById(Number(organizationId))
          await getGuestList(Number(organizationId), guestListId)

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${organizationError}`)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (guestList && guestList.members) {
      const filtered = guestList.members.filter(
        (member) =>
          member.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.guests.some((guest) =>
            guest.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
      )
      setFilteredMembers(filtered)
      setHasResults(filtered.length > 0)
    }
  }, [searchTerm, guestList])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const addGuestParam = urlSearchParams.get("addGuest")

    if (addGuestParam && addGuestParam.toLowerCase() === "true") {
      setIsDialogOpen(true)
    }
  }, [])

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleCreateGuest = async () => {
    if (!newGuestName) {
      toast.error("Please enter a guest name")
      return
    }

    await createGuestListGuest(
      Number(localStorage.getItem("activeOrganizationId")),
      guestListId,
      { name: newGuestName },
      createGuestSuccess
    )
  }

  const createGuestSuccess = async () => {
    toast.success(`Guest "${newGuestName}" created`)
    setIsDialogOpen(false)
    setNewGuestName("")
    await getGuestList(
      Number(localStorage.getItem("activeOrganizationId")),
      guestListId
    )
  }

  const handleDeleteGuest = async (guestId: number) => {
    await deleteGuestListGuest(
      Number(localStorage.getItem("activeOrganizationId")),
      guestListId,
      guestId,
      deleteGuestSuccess
    )
  }

  const deleteGuestSuccess = async () => {
    toast.success("Guest successfully deleted")
    setIsDialogOpen(false)
    await getGuestList(
      Number(localStorage.getItem("activeOrganizationId")),
      guestListId
    )
  }

  if (organizationLoading || listLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  if (!guestList) {
    return <ScreenContainer title={"Guest list not found"}></ScreenContainer>
  }

  const editingEnabled = guestList.editing_enabled
  const currentUserGuestList = guestList.members.find(
    (member) => member.user.id === currentUserId
  )

  const props = {
    currentUserId,
    editingEnabled,
    handleDeleteGuest
  }

  return (
    <ScreenContainer
      icon={<SettingsIcon />}
      iconAction={() => setIsUpdateDialogOpen(true)}
    >
      <Box className={styles.icon} onClick={() => navigate('/guest-lists')}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Box>
      <Box className={styles.screenContainer}>
        <Box className={styles.listContainer} >
          <Box className={styles.header}>
        <Box className={styles.name}>
          <IconButton className={styles.settingsIcon} onClick={() => setIsUpdateDialogOpen(true)}>
            <SettingsIcon />
          </IconButton>
          {guestList.name}
        </Box>
      {listError && <ErrorComponent error={listError} />}
        <TextField
          fullWidth
          label="Search Members and Guests"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.search}
        />
      </Box>
      {hasResults ? (
        <List className={styles.list}>
          {filteredMembers &&
            filteredMembers.map((member) => (
              <GuestListDetail member={member} props={props} />
            ))}
        </List>
      ) : (
        <Typography textAlign="center" sx={{ mt: 2 }}>
          No members or guests found matching your search term "{searchTerm}"
        </Typography>
      )}
      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
        }}
        onClick={handleOpenDialog}
        disabled={
          !editingEnabled ||
          (currentUserGuestList &&
            currentUserGuestList.guests.length >= guestList.max_guests)
        }
      >
        <AddIcon />
      </Fab>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Guest</DialogTitle>
        <DialogContent>
          {createGuestError && <ErrorComponent error={createGuestError} />}
          <DialogContentText>
            Enter the name of the guest to add them to your guest list.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Guest Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newGuestName}
            onChange={(e) => setNewGuestName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleCreateGuest}
            color="primary"
            disabled={createGuestLoading}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <GuestListDialog
        isOpen={isUpdateDialogOpen}
        close={() => setIsUpdateDialogOpen(false)}
        onSuccess={() =>
          getGuestList(
            Number(localStorage.getItem("activeOrganizationId")),
            guestListId
          )
        }
        guestList={guestList}
      />
      </Box>
      </Box>
    </ScreenContainer>
  )
}

export default GuestListDetailsScreen
