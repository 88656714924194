import { Box, Button, CircularProgress, Typography } from "@mui/material"
import React, { FunctionComponent, useEffect, useState } from "react"
import ErrorComponent from "../../components/shared/Error"
import ScreenContainer from "../../components/shared/ScreenContainer"
import useGetHouseById from "../Organizations/hooks/useGetHouseById"
import useGetHouseMembers from "../Organizations/hooks/useGetHouseMembers"
import MemberPointsTable from "./components/MemberPointsTable"
import { scopes } from "../../scopes"
import { useAuth } from "../../services/auth-service"
import styles from "./PointsScreen.module.css"
import AddButton from "../../components/shared/AddButton"
import CreatePointItemDialog from "./components/CreatePointItemDialog"
import { PointItem, PointsResponse } from "../../types/points"
import { OrganizationMember } from "../../types"

const PointsScreen: FunctionComponent = () => {
  const {
    getHouseById,
    house,
    loading: houseLoading,
    error: houseError,
  } = useGetHouseById()
  const {
    getHouseMembers,
    paginationData,
    members,
    loading: membersLoading,
    error: membersError,
  } = useGetHouseMembers()
  const { hasScope } = useAuth()
  const [createPointsOpen, setCreatePointsOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const houseId = localStorage.getItem("activeOrganizationId")

        if (houseId) {
          await getHouseById(Number(houseId))
          await getHouseMembers(Number(houseId))

          if (house) {
            localStorage.setItem("activeOrganization", JSON.stringify(house))
          }
        }
      } catch (err) {
        console.error(`Error loading organization: ${houseError}`)
      }
    }

    fetchData()
  }, [])

  const canCreatePoints = hasScope(scopes.organization.member.points.create)

  const createPointsSuccess = () => {
    setCreatePointsOpen(false)
    getHouseMembers(house?.id!)
  }

  if (houseLoading) {
    return (
      <ScreenContainer>
        <Box
          display="flex"
          width="100%"
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            mb: "24px",
          }}
        >
          <CircularProgress />
        </Box>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer
      requiresScope={scopes.organization.pointItems.read}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          flexDirection: "column",
          mb: "24px",
        }}
        className={styles.pointsScreenContainer}
      >
        {membersError && <ErrorComponent error={membersError} />}
        {membersLoading && <CircularProgress />}
        <Typography variant="h3" className={styles.title}>
          Points Management
        </Typography>
        <MemberPointsTable
          members={members}
          paginationData={paginationData}
          refreshData={() => getHouseMembers(house?.id!)}
        />
      </Box>
      {canCreatePoints && (
        <Box className={styles.createPointItemButtonContainer} onClick={() => setCreatePointsOpen(true)}>
          <AddButton className="className" />
        </Box>
      )}
      <CreatePointItemDialog
        isOpen={createPointsOpen}
        close={() => setCreatePointsOpen(false)}
        onSuccess={() => createPointsSuccess()}
      />
    </ScreenContainer>
  )
}

export default PointsScreen
