import React, { FunctionComponent, useEffect, useState } from "react";
import BasicTableRow from "../../../components/shared/BasicTable/BasicTableRow";
import BasicTableCell from "../../../components/shared/BasicTable/BasicTableCell";
import { OrganizationMember } from "../../../types";
import { Avatar, Box, Input, Typography } from "@mui/material";
import { PointsResponse, PointItem } from "../../../types/points";
import styles from './MemberPointsRow.module.css';
import Swal from "sweetalert2";
import useUpdatePointItems from "../hooks/useUpdatePointItems";
import toast from "react-hot-toast";
import useGetHouseMembers from "../../Organizations/hooks/useGetHouseMembers";

type MemberPointsRowProps = {
  member: OrganizationMember | null;
  refreshData: () => void;
  pointItems: PointsResponse | undefined;
  columns: string[];
  isMobile: boolean;
};

const MemberPointsRow: FunctionComponent<MemberPointsRowProps> = ({
  member,
  pointItems,
  columns,
  refreshData,
  isMobile,
}) => {
  const [updatedPoints, setUpdatedPoints] = useState<{ [key: string]: number | "" }>({});
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const { loading, error, updatePointItem } = useUpdatePointItems();
  const { getHouseMembers } = useGetHouseMembers();

  const inputValue = (column: string, memberId: number, pointItem: PointItem) => {
    const key = `${memberId}-${column}`;
    if (updatedPoints[key] !== undefined) {
      return updatedPoints[key];
    }
    
    const foundPoints = pointItem.points.find((p) => p.id === memberId)?.points;
    return foundPoints !== undefined ? foundPoints : "";
  };

  const onPointChange = (column: string, points: string, memberId: number) => {
    setHasChanged(true);
    const key = `${memberId}-${column}`;
    setUpdatedPoints((prev) => ({
      ...prev,
      [key]: points === "" ? "" : Number(points),
    }));
  };

  const onInputBlur = (index: number, newValue: string | undefined, memberName: string, column: string, memberId: number) => {
    if (!newValue || Number(newValue) < 0) {
      toast.error("Points must be a number greater than or equal to 0");
      return;
    }
    if(hasChanged) {
    Swal.fire({
      title: "Update Points?",
      text: `Update ${memberName}'s points for ${column} to ${newValue}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    })
      .then((result) => {
        if (result.isConfirmed && pointItems && member) {
          updatePointItem(
            pointItems.items[index].id,
            member.id,
            Number(localStorage.getItem("activeOrganizationId")),
            { value: newValue },
            refreshData
          );
        } else if (!result.isConfirmed) {
          refreshData();
          setUpdatedPoints({})
        }
      })
      .catch(() => {
        toast.error("Error updating points");
      });
      setHasChanged(false)
    }
  };

  useEffect(() => {
    getHouseMembers(Number(localStorage.getItem("activeOrganizationId")));
  }, []);

  if (!member) {
    return (
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        No members found
      </Typography>
    );
  }

  return (
    <BasicTableRow key={member.id} className={styles.row}>
      <BasicTableCell align="center" className={styles.nameAndAvatar} component="th" scope="row">
        <Avatar alt={member.user.name} src={`${member.user.profile_photo}`} />
        <Box className={styles.nameContainer}>{member.user.name}</Box>
      </BasicTableCell>
      <BasicTableCell align="center" component="th" scope="row">
        {member.points} Point{member.points === 1 ? "" : "s"}
      </BasicTableCell>
      {pointItems?.items.map((point, index) => (
        <BasicTableCell align="center" component="th" scope="row" key={index}>
          <Input
            sx={{ textAlign: "center" }}
            type="number"
            inputProps={{ min: 0 }}
            value={inputValue(point.title, member.id, point)}
            className={styles.pointInput}
            onChange={(e) => onPointChange(point.title, e.target.value, member.id)}
            onBlur={(e) => {
              onInputBlur(index, e.target.value, member.user.name, point.title, member.id);
            }}
          />
        </BasicTableCell>
      ))}
    </BasicTableRow>
  );
};
export default MemberPointsRow;